import React from 'react';
import './style.sass';

export const SubTitleTypes = {
  article: 'article',
  map: 'map',
  news: 'news',
  shrine: 'shrine',
  related: 'related',
};

export default props => {
  const { type } = props;
  return (
    <div className="subTitle">
      <div className={`subTitle__label subTitle__label--${type}`} />
    </div>
  );
};
